// REACT
import React from "react"
// MUI
import { Container, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
// GATSBY
import Img from "gatsby-image"

// STYLES
const useStyles = makeStyles({
  img: {
    display: "block",
    overflow: "hidden",
    width: "100%",
    marginBottom: "0.25rem",
  },
})

// COMPONENT FUNCTION
export const ImageWithCaption = ({
  fluid,
  caption,
  displayCaption = false,
  maxWidth = 800,
}) => {
  // STYLE OBJECT
  const classes = useStyles()

  // COMPONENT
  return (
    <>
      <Container className={classes.img} style={{ maxWidth: maxWidth }}>
        <Img fluid={fluid} alt={caption} className={classes.img} />
      </Container>
      {displayCaption ? (
        <Typography variant="caption" align="center" display="block">
          {caption}
        </Typography>
      ) : null}
    </>
  )
}
